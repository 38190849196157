// открытие гамбургер меню, корзины, меню поиска/позвонить/написать
const body = document.body;
const bodyUnFixed = () => {
  body.style.overflow = "";
};

const bodyFixed = () => {
  body.style.overflow = "hidden";
};
const bodyToggleFixed = () => {
  if (body.style.overflow === "hidden") {
    bodyUnFixed();
  } else {
    bodyFixed();
  }
};

const toggleMenu = () => {
  if (!document.querySelector(".header-mobile__menu").classList.contains("header-mobile__menu_active")) {
    document.querySelector(".header-mobile__menu-btn").classList.add("header-mobile__menu-btn_active");
    document.querySelector(".header-mobile__menu").classList.add("header-mobile__menu_active");
    bodyToggleFixed();
  } else {
    bodyToggleFixed();
    document.querySelector(".header-mobile__menu-btn").classList.remove("header-mobile__menu-btn_active");
    document.querySelector(".header-mobile__menu").classList.remove("header-mobile__menu_active");
  }
};

$(".header-mobile__menu-btn").on("click", toggleMenu);

const closeSearch = () => {
  bodyUnFixed();
  $(".footer-mobile__nav-link_search").removeClass("footer-mobile__nav-link_active");
  $(".footer-search").removeClass("footer-search_opened");
};

const toggleSearch = () => {
  if (!$(".footer-mobile__nav-link_search").hasClass("footer-mobile__nav-link_active")) {
    $(".footer-mobile__nav-link_search").addClass("footer-mobile__nav-link_active");
    $(".footer-search").addClass("footer-search_opened");
    bodyFixed();
  } else {
    closeSearch();
  }                                         
};

const closeCatalog = () => {
  bodyUnFixed();
  $(".footer-mobile__nav-link_catalog").removeClass("footer-mobile__nav-link_active");
  $(".hidden-catalog").removeClass("show");
};

const toggleCatalog = () => {
  if (!$(".footer-mobile__nav-link_catalog").hasClass("footer-mobile__nav-link_active")) {
    $(".footer-mobile__nav-link_catalog").addClass("footer-mobile__nav-link_active");
    $(".hidden-catalog").addClass("show");
    bodyFixed();
  } else {
    closeCatalog();
  }
};

const toggleChat = () => {
  $(".footer-mobile__chat").toggleClass("footer-mobile__chat_opened");
  $(".footer-mobile__nav-link_chat").toggleClass("footer-mobile__nav-link_active");
};

const closeChat = () => {
  $(".footer-mobile__chat").removeClass("footer-mobile__chat_opened");
  $(".footer-mobile__nav-link_chat").removeClass("footer-mobile__nav-link_active");
};

const toggleCall = () => {
  $(".footer-mobile__call").toggleClass("footer-mobile__call_opened");
  $(".footer-mobile__nav-link_call").toggleClass("footer-mobile__nav-link_active");
};

const closeCall = () => {
  $(".footer-mobile__call").removeClass("footer-mobile__call_opened");
  $(".footer-mobile__nav-link_call").removeClass("footer-mobile__nav-link_active");
};

$(".footer-mobile__nav-link_catalog").on("click", () => {
  toggleCatalog();
  closeSearch();
  closeChat();
  closeCall();
});

$(".footer-mobile__close_catalog").on("click", () => {
  closeCatalog();
});

$(".footer-mobile__nav-link_search").on("click", () => {
  toggleSearch();
  closeCatalog();
  closeChat();
  closeCall();
});

$(".footer-mobile__close_search").on("click", () => {
  closeSearch();
});

$(".footer-mobile__nav-link_chat").on("click", () => {
  toggleChat();
  closeCatalog();
  closeSearch();
  closeCall();
  bodyUnFixed();
});

$(".footer-mobile__close_chat").on("click", () => {
  closeChat();
});

$(".footer-mobile__nav-link_call").on("click", () => {
  toggleCall();
  closeCatalog();
  closeSearch();
  closeChat();
  bodyUnFixed();
});

$(".footer-mobile__close_call").on("click", () => {
  closeCall();
});

$(".header-mobile__basket-dropdown.ui.dropdown")
  .on("touchstart", e => {
    if (!e.cancelable) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  })
  .dropdown({on: "click"})
;

// коней кода для открытия гамбургер меню, корзины, меню поиска/позвонить/написать
$(".header-mobile__title-back").click(() => window.history.back());


const openFilters = () => {
  $(".filters").addClass("filters_opened");
  bodyFixed();
};

const closeFilters = (e) => {
  if (e.target.classList.contains("filters__close-btn") || e.target.classList.contains("filters_opened")) {
    $(".filters").removeClass("filters_opened");
    bodyUnFixed();
  }
};

$(".catalog__filters-open-btn").on("click", openFilters);

$(".filters").on("click", closeFilters);

// Модалка спасибо

const thanksModal = $(".ui.modal.thanks-modal");
const thanksModalText = $(".thanks-modal__text");

const openThanksModal = (text = "В скором времени с вами свяжется менеджер и уточнит адрес и стоимость доставки") => {
  thanksModalText.text(text);
  thanksModal.modal("show");
};

//  модалка предупреждение о доставке
$(".checkout__delivery-attention-modal")
  .modal("attach events", ".checkout__attention-open-btn", "show")
;

// модалки оригиналов отзыва
// $(".feedbacks__modal.modal")
//   .each(function () {
//     $(this).modal("attach events", `.feedbacks__original-link[data-id="${$(this).data("id")}"]`, "show");
//   })
// ;

// $(".feedbacks__original-link").on("click", function() {
//   var feedback_id = $(this).data("id");
//   var feedback_modal = $(`.feedbacks__modal.modal[data-id="${feedback_id}}"]`);
//   console.log(feedback_modal);
//   feedback_modal.modal("show");
// });

$(".feedbacks-create-modal.modal")
  .modal("attach events", ".feedbacks__create-btn", "show")
;

const timeValidationRule = {identifier: "time", rules: [{type: "regExp[^[\\s\\S]*$]"}]};
const sizeValidationRule = {identifier: "size", rules: [{type: "regExp[^[\\s\\S]*$]"}]};
const cityValidationRule = {identifier: "city", rules: [{type: "regExp[^[\\s\\S]*$]"}]};
const locationValidationRule = {identifier: "location", rules: [{type: "regExp[^[\\s\\S]*$]"}]};
const fullnameValidationRule = {identifier: "fullname", rules: [{type: "empty"}]};
const consentPersonalData = {identifier: "consentPersonalData", rules: [{type: "checked"}]};
const phoneValidationRule = {identifier: "phone"};
const emailValidationRule = {
  identifier: "email",
  rules: [{type: "regExp[/^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i]"}]
};
const emailValidationRuleNotRequired = {
  identifier: "email",
  rules: [{type: "regExp[^$|^([\\w\\.\\-]+)@([\\w\\-]+)((\\.(\\w){2,3})+)$]"}]
};

const receiverValidationRule = {identifier: "receiver", rules: [{type: "empty"}]};
const regionValidationRule = {identifier: "region", rules: [{type: "regExp[^[\\s\\S]*$]"}]};
const countryValidationRule = {identifier: "country", rules: [{type: "regExp[^[\\s\\S]*$]"}]};


// для работы чекбоксов
$(".ui.checkbox").checkbox();

$(".ui.radio.checkbox.checkbox-radio_btn")
  .checkbox()
;

$(".ui.radio.checkbox.checkbox-radio_variant")
  .checkbox({
    onChange: changeIconCheckbox
  })
;

function changeIconCheckbox() {
  const value = this.value;

  function activeChange() {
    if (this.dataset.value === value) {
      this.classList.add("active");
    } else {
      this.classList.remove("active");
    }
  }

  $(`.checkbox-radio__desc[data-name="${this.name}"]`).each(activeChange);
}

// форма Обратная связь на странице контактов
$(".contacts-feedback__form.ui.form")
  .form({
    onSuccess: (e, values) => {
      e.preventDefault();
      var form_data = new FormData(e.target);
      $.ajax({
        url: 'send.php',
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function(php_script_response){}
      });
      $(".contacts-feedback__form.ui.form").trigger("reset");
      $(".contacts-feedback__form.ui.form.form .input__field").each(function() {
          if ($(this).hasClass("success")) {
            $(this).removeClass("success");
          }
        });
      
      openThanksModal("Спасибо за ваше время, уделенное на оставление пожеланий. Ваше мнение помогает нам совершенствоваться и дарить вам лучшие эмоции!");
    },
    fields: {
      fullname: fullnameValidationRule,
      consentPersonalData: consentPersonalData,
    },
    onValid: onValid,
    onInvalid: onInvalid,
    on: "change"
  })
;

// форма Отправки отзыва
$(".feedbacks-create-modal__form.ui.form")
  .form({
    onSuccess: (e, values) => {
      e.preventDefault();
      var form_data = new FormData(e.target);
      var files = document.getElementById("feedback-images").files;
      if (files) {
        for (var index = 0; index < files.length; index++) {
          form_data.append("images", files[index]);
        }
      }
      $.ajax({
        url: 'send.php',
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function(php_script_response){}
      });
      $(".feedbacks-create-modal__form.ui.form").trigger("reset");
      $(".feedbacks-create-modal__form.ui.form .input__field").each(function() {
        if ($(this).hasClass("success")) {
          $(this).removeClass("success");
        }
      });
      $( "#feedback-images" ).replaceWith('<input class="input image-upload__input" id="feedback-images" accept="image/*" name="userfile[]" type="file" multiple="multiple"></input>');
      initImagesUploader('feedback-images');
      $("#feedback-images_preview").html("");

      openThanksModal("Мы искренне благодарим вас за оставленный отзыв. Ваше мнение очень важно для нас. Оно вдохновляет и помогает нам делать нашу работу еще лучше!");
    },
    fields: {
      fullname: fullnameValidationRule,
      feedback: {identifier: "feedback", rules: [{type: "empty"}]}
    },
    onValid: onValid,
    onInvalid: onInvalid,
    on: "change"
  })
;

// формы на странице реставрации
$(".services-form_icon.ui.form").form({
  onSuccess: function onSuccess(e, values) {
    e.preventDefault();
    var form_data = new FormData(e.target);
    var files = document.getElementById("services-form-image-icon").files;
    for (var index = 0; index < files.length; index++) {
      form_data.append("images", files[index]);
    }
    $.ajax({
      url: 'send.php',
      dataType: 'text',
      cache: false,
      contentType: false,
      processData: false,
      data: form_data,
      type: 'post',
      success: function(php_script_response){}
    });
    $(".services-form_icon.ui.form").trigger("reset");
    $(".services-form_icon.ui.form .input__field").each(function() {
      if ($(this).hasClass("success")) {
        $(this).removeClass("success");
      }
    });
    $( "#services-form-image-icon" ).replaceWith('<input class="input image-upload__input" name="userfile[]" type="file" multiple="multiple" accept="image/*" id="services-form-image-icon">');
    initImagesUploader('services-form-image-icon');
    $("#services-form-image-icon_preview").html("");
    openThanksModal("Благодарим вас за доверие и за вашу заявку на реставрацию иконы! С вами свяжется руководитель мастерской для уточнения деталей. Затем, наша команда мастеров будет тщательно и с большим уважением работать над возрождением вашей иконы, чтобы она вновь стала источником света и благоговения.");
  },
  fields: {
    fullname: fullnameValidationRule,
    phone: phoneValidationRule,
    time: timeValidationRule,
    city: cityValidationRule,
    size: sizeValidationRule,
    consentPersonalData: consentPersonalData
  },
  onValid: onValid,
  onInvalid: onInvalid,
  onFailure: onFailure,
  on: "change"
});

$(".services-form_paper.ui.form").form({
  onSuccess: function onSuccess(e, values) {
      e.preventDefault();
      var form_data = new FormData(e.target);
      var files = document.getElementById("services-form-image-paper").files;
      if (files) {
        for (var index = 0; index < files.length; index++) {
          form_data.append("images", files[index]);
        }
      }
      $.ajax({
        url: 'send.php',
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function(php_script_response){}
      });
      $(".services-form_paper.ui.form").trigger("reset");
      
      $(".services-form_paper.ui.form .input__field").each(function() {
        if ($(this).hasClass("success")) {
          $(this).removeClass("success");
        }
      });
      $( "#services-form-image-paper" ).replaceWith('<input class="input image-upload__input" name="userfile[]" type="file" multiple="multiple" accept="image/*" id="services-form-image-paper">');
      initImagesUploader('services-form-image-paper');
      $("#services-form-image-paper_preview").html("");
      openThanksModal("Благодарим вас за доверие и за вашу заявку на реставрацию бумажного изделия. С вами свяжется руководитель мастерской для уточнения деталей. Затем, наша команда мастеров восстановит его с максимальной аккуратностью и уважением к исторической ценности.");
    },
    fields: {
      fullname: fullnameValidationRule,
      phone: phoneValidationRule,
      time: timeValidationRule,
      city: cityValidationRule,
      size: sizeValidationRule,
      consentPersonalData: consentPersonalData,
    },
    onValid: onValid,
    onInvalid: onInvalid,
    onFailure: onFailure,
    on: "change"
  });


// форма звонка в футере
$(".call-form__form.ui.form")
  .form({
    onSuccess: (e, values) => {
      e.preventDefault();
      var form_data = new FormData(e.target);
      $.ajax({
        url: 'send.php',
        dataType: 'text',
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function(php_script_response){}
      });
      $(".call-form__form.ui.form").trigger("reset");
      $(".call-form__form.ui.form .input__field").each(function() {
        if ($(this).hasClass("success")) {
          $(this).removeClass("success");
        }
      });
      openThanksModal("Спасибо за вашу заявку! Мы уже готовимся к нашему разговору и с нетерпением ждем возможности обсудить ваши пожелания и вопросы.");
      $(".call-modal.modal")
        .modal("hide")
      ;
      closeCall();
    },
    fields: {
      fullname: fullnameValidationRule,
      phone: phoneValidationRule,
    },
    onValid: onValid,
    onInvalid: onInvalid,
    on: "change"
  });

// формы на странице заказ

$(".checkout__order-form .order")
  .form({
    fields: {
      receiver: receiverValidationRule,
      phone: phoneValidationRule,
      region: regionValidationRule,
      country: countryValidationRule,
      consentPersonalData: consentPersonalData,
      email: emailValidationRuleNotRequired
    },
    onValid: onValid,
    onInvalid: onInvalid,
    on: "change"
  });

// $(".checkout__order-form .order")
//   .form({
//     onSuccess: (e, values) => {
//       e.preventDefault();

//       if (values.messenger === "on") {

//         values.messenger = $("input[name=\"messenger\"]:checked").val();
//       }
//       $(".checkout__order-form .order").trigger("reset");
//       openThanksModal("Благодарим вас за выбор нашей мастерской! Мы приложим все усилия, чтобы ваши ожидания были оправданы, и икона, которую мы создали, стала источником духовной силы и благоговения.");
//     },
//     fields: {
//       fullname: fullnameValidationRule,
//       phone: phoneValidationRule,
//       time: timeValidationRule,
//       location: locationValidationRule,
//       size: sizeValidationRule,
//       consentPersonalData: consentPersonalData,
//       email: emailValidationRuleNotRequired
//     },
//     onValid: onValid,
//     onInvalid: onInvalid,
//     on: "change"
//   });

// форма на странице икона
// $(".icon-form__form.ui.form")
//   .form({
//     onSuccess: function (e) {
//       e.preventDefault();
//       const valuesArray = $(this).serializeArray();
//       const form_data = new FormData();
//       const files = document.getElementById("image").files;
//       for (let index = 0; index < files.length; index++) {
//         form_data.append("images", files[index]);
//       }
//       valuesArray.forEach(({value, name}) => {
//         if (value === "") return;
//         if (name.includes("name")) {
//           form_data.append("name", value);
//           return;
//         }
//         if (name.includes("birthday")) {
//           form_data.append("birthday", value);
//           return;
//         }
//         form_data.append(name, value);
//       });
//       console.log(new URLSearchParams(form_data).toString());
//       addBasketIcon({
//         iconId: "1",
//         iconPrice: document.querySelector(".icon-form__price").textContent,
//         iconPhotoWebp: document.querySelector(".icon-slider__image").src.replace("png", "webp"),
//         iconPhoto: document.querySelector(".icon-slider__image").src,
//         iconLink: "",
//         iconName: document.querySelector(".icon-form__title").textContent
//       });
//     },
//   });

function onValid() {
  if ($(this).val()) {

    $(this).parent().closest(".input__field").addClass("success");
  } else {

    $(this).parent().closest(".input__field").removeClass("success");
  }
}

function onInvalid() {

  $(this).parent().closest(".input__field").removeClass("success");
}

function onFailure() {
  const offset = $("html,body").width() > 1023 ? 162 : 60;
  $("html,body").animate({scrollTop: $(".field.input__field.error").offset().top - offset}, 400);
  return false;
}

// функция для включения выбора изображений с превью
const initImagesUploader = (imageInputId) => {
  const imageInput = $(`#${imageInputId}`);
  const imagesPreviewBox = $(`#${imageInputId}_preview`);
  const imagesDefaultPreviewBox = $(`#${imageInputId}_default-preview`);
  const isMultiple = imageInput.prop("multiple");

  const imagesResetBtn = $(`#${imageInputId}_reset-btn`);
  let fileArr = [];
  imageInput.change(function () {
    $(`#image-upload_error`).html('');
    var error = '';
    jQuery.each($(imageInput)[0].files, function(i, file) {
      var fn = file.name;

      if(file.name.length < 1) {
          error = error + ' Слишком короткое имя! ';
      } //Проверка на длину имени
      if(file.size > 1024 * 3 *1024) {
          error = error +  file.name + '- Слишком большой файл .';
      } //Проверка размера файла
      if(file.type != 'image/png' && file.type != 'image/jpg' && !file.type != 'image/gif' && file.type != 'image/jpeg' ) {
          error = error + 'Файл  ' + file.name + '  имеет недопустимое расширение  (png, jpg, gif) ';
      } //Проверка типа файлов
    });

    if (error != '') {
      $(`#image-upload_error`).html(error);
      imagesPreviewBox.html("");
      imagesDefaultPreviewBox.removeClass("hidden");
      imageInput.prop("files", FileListItem([]));
      
    } else {
      
      var total_file = imageInput.prop("files");
      if (!total_file.length) return;
      // check if fileArr length is greater than 0
      if (!isMultiple) {
        if (fileArr.length > 0) fileArr = [];
        imagesPreviewBox.html("");
        imagesDefaultPreviewBox.addClass("hidden");
      }
      for (var i = 0; i < total_file.length; i++) {
        fileArr.push(total_file[i]);
        imagesPreviewBox.append("<div class='image-upload__img-container' id='" + imageInputId + "-image-upload__img-container-" + i + "'><img src='" + URL.createObjectURL(event.target.files[i]) + "' class='image-upload__img-preview' title='" + total_file[i].name + "'><button value='" + imageInputId + "-image-upload__img-container-" + i + "' class='image-upload__img-del-btn " + imageInputId + "-img-del-btn' role='" + total_file[i].name + "'></button></div>");
      }
      if (isMultiple) {
        imageInput.prop("files", FileListItem(fileArr));
      }

    }
  });

  imagesResetBtn.click(function () {
    imagesPreviewBox.html("");
    imagesDefaultPreviewBox.removeClass("hidden");
    imageInput.prop("files", FileListItem([]));
  });
  $("body").on("click", `.${imageInputId}-img-del-btn`, function (evt) {
    var divName = this.value;
    var fileName = $(this).attr("role");
    $(`#${divName}`).remove();

    for (var i = 0; i < fileArr.length; i++) {
      if (fileArr[i].name === fileName) {
        fileArr.splice(i, 1);
      }
    }

    if (!fileArr.length) {
      imagesDefaultPreviewBox.removeClass("hidden");
    }

    imageInput.prop("files", FileListItem(fileArr));
    evt.preventDefault();
  });


};

// вспомогательная функция для включения выбора изображений с превью
function FileListItem(file) {
  file = [].slice.call(Array.isArray(file) ? file : arguments);
  for (var c, b = c = file.length, d = !0; b-- && d;) d = file[b] instanceof File;
  if (!d) throw new TypeError("expected argument to FileList is File or array of File objects");
  for (b = (new ClipboardEvent("")).clipboardData || new DataTransfer; c--;) b.items.add(file[c]);
  return b.files;
}

// поиск всех инпутов для загрузки изображений и активация функций предпросмотра/добавления/удаления
const imagesUploaderList = document.querySelectorAll(".image-upload__input");
imagesUploaderList.forEach(input => {
  initImagesUploader(input.id);
});


// переключение табов на странице реставрация

const servicesTabs = document.querySelectorAll(".button.tab.services");
const servicesPages = document.querySelectorAll(".services__page");

function changeTab() {
  if ([...this.classList].includes("active")) return;

  servicesTabs.forEach((tab) => {
    if (this.dataset.type === tab.dataset.type) {
      tab.classList.add("active");
    } else {
      tab.classList.remove("active");
    }
  });
  servicesPages.forEach((page) => {
    if (this.dataset.type === page.dataset.page) {
      page.classList.add("active");
    } else {
      page.classList.remove("active");
    }
  });

}

servicesTabs.forEach(tab => tab.addEventListener("click", changeTab));


// Все слайдеры на странице

// слайдер на главной
new Swiper(".latest-icons__swiper", {
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    480: {
      slidesPerView: 2.33,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 3.33,
      spaceBetween: 10,
    },
    900: {
      slidesPerView: 4.33,
      spaceBetween: 20,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// увеличение фото слайдера главной
const photo = new SmartPhoto(".latest-icon__image-link", {
  resizeStyle: "fit"
});

// увеличение фото слайдера на стр реставрация
const servicesPhoto = new SmartPhoto(".services-quote__example-image-link", {
  resizeStyle: "fit"
});

function scrollSmartPhotoCurrent() {
  $($(this).find(".smartphoto-nav ul")).animate({
    scrollLeft: $(this).find("a.current").offset().left
  }, 300);
}

photo.on("open", scrollSmartPhotoCurrent);
photo.on("change", scrollSmartPhotoCurrent);
servicesPhoto.on("open", scrollSmartPhotoCurrent);
servicesPhoto.on("change", scrollSmartPhotoCurrent);

// слайдер на страницах реставрации бумаги
new Swiper(".services-quote__swiper", {
  slidesPerView: 1,
  spaceBetween: 10,
  grid: {
    fill: "row",
    rows: 1
  },
  breakpoints: {
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
      grid: {
        fill: "row",
        rows: 1
      },
    },
    767: {
      slidesPerView: 4,
      spaceBetween: 20,
      grid: {
        fill: "row",
        rows: 2
      }
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// слайдер на страницах реставрации икон
new Swiper(".services-quote-icon__swiper", {
  slidesPerView: 1,
  spaceBetween: 10,
  grid: {
    fill: "row",
    rows: 1
  },
  breakpoints: {
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
      grid: {
        fill: "row",
        rows: 1
      },
    },
    767: {
      slidesPerView: 3,
      spaceBetween: 20,
      grid: {
        fill: "row",
        rows: 2
      }
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// плавный скролл для якорных ссылок
$("body").on("click", "[href*=\"#\"]", function (e) {
  if (!this.hash) return;
  var fixed_offset = 100;
  $("html,body").stop().animate({scrollTop: $(this.hash).offset()?.top - fixed_offset}, 600);
  e.preventDefault();
});


// кнопка добавления родственника на странице семейной иконы
const namesInputList = $("#names_list");
var familyInputsCount = 1;
$("#names_add-btn").click((e) => {
  e.preventDefault();
  familyInputsCount++;
  namesInputList.append("<div class=\"field input__field input-list__field\">\n" +
    "                      <input class=\"input icon-form__input\" placeholder=\"Имя\" name=\"name_" + familyInputsCount + "\" type=\"text\" required=\"\">\n" +
    "                      <input class=\"input icon-form__input\" placeholder=\"Дата рождения\" name=\"birthday_" + familyInputsCount + "\" type=\"date\" required=\"\" aria-required=\"true\" data-placeholder=\"Дата рождения\">\n" +
    "                       <div class=\"icon-form__remove js-remove-field\" onclick=\"decreaseCount()\"><svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z\" fill=\"#095063\"/></svg></div>" +
    "                    </div>");
});

$('body').on("click", ".js-remove-field", function(e) {
  $(e.target).parent(".input-list__field").remove();
});

const rangeNames = ["price"];
const rangeSliderInit = (name) => { // создаем функцию инициализации слайдера
  const range = document.getElementById(`${name}-range`); // Ищем слайдер
  const inputMin = document.getElementById(`${name}-min`); // Ищем input с меньшим значнием
  const inputMax = document.getElementById(`${name}-max`); // Ищем input с большим значнием

  if (!range || !inputMin || !inputMax) return; // если этих элементов нет, прекращаем выполнение функции, чтобы не было ошибок

  const inputs = [inputMin, inputMax]; // создаем массив из меньшего и большего значения

  noUiSlider.create(range, { // инициализируем слайдер
      start: [inputMin.value, inputMax.value], // устанавливаем начальные значения
      connect: true, // указываем что нужно показывать выбранный диапазон
      range: { // устанавливаем минимальное и максимальное значения
        "min": Number(inputMin.min),
        "max": Number(inputMax.max)
      },
      step: 1, // шаг изменения значений
    }
  );

  range.noUiSlider.on("update", function (values, handle) { // при изменений положения элементов управления слайдера изменяем соответствующие значения
    inputs[handle].value = parseInt(values[handle]);
  });

  inputMin.addEventListener("change", function () { // при изменении меньшего значения в input - меняем положение соответствующего элемента управления
    range.noUiSlider.set([this.value, null]);
  });

  inputMax.addEventListener("change", function () { // при изменении большего значения в input - меняем положение соответствующего элемента управления
    range.noUiSlider.set([null, this.value]);
  });

};

const init = () => {
  rangeNames.forEach(range => rangeSliderInit(range));
};

function formAllReset() {
  console.log("All form reset");
  rangeNames.forEach(range => document.getElementById(`${range}-range`).noUiSlider.reset());
}

function formSubmit(e) {
  e.preventDefault();
  const data = $(".filters__form").serializeArray();
  console.log($(".filters__form").serialize());
  console.log(data);
  // скрыть меню фильтров на мобилке
  $(".filters").removeClass("filters_opened");
  bodyUnFixed();
}

window.addEventListener("DOMContentLoaded", init); // запускаем функцию init, когда документ будет загружен и готов к взаимодействию

$(".filters__btn_reset").on("click", formAllReset);
$(".filters__form").submit(formSubmit);


// слайдеры на странице икон
const swiper = new Swiper(".icon-slider__thumbs-swiper", {
  spaceBetween: 17.5,
  slidesPerView: "2",
  breakpoints: {
    1000: {
      slidesPerView: "3",
      spaceBetween: 22.5,
    },
    1300: {
      slidesPerView: "4",
      spaceBetween: 22.5,
    },
  },
  freeMode: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  watchSlidesProgress: true,
});
const swiper2 = new Swiper(".icon-slider__main-swiper", {
  spaceBetween: 10,
  slidesPerView: 1,
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  autoHeight: true,
  thumbs: {
    swiper: swiper,
  },
});

const basketDropdownDesktop = $(".header__basket-dropdown.ui.dropdown.desktop");
const basketDropdownMobile = $(".header-mobile__basket-dropdown.ui.dropdown");
const basketDropdownPriceDesktop = $(".header__basket-price_desktop");
const basketDropdownPriceMobile = $(".header__basket-price.header-mobile__basket-price");
const basketList = $(".basket__list");
const basketTotalPrice = $(".basket__total-price");

function addBasketIcon({iconId, iconPrice, iconPhotoWebp, iconPhoto, iconLink, iconName}) {
  if ($(`.basket__item[data-icon-id=${iconId}]`, basketList[0]).length === 0) {
    $(`.basket__item[data-icon-id='empty']`).each(function () {
      this.remove();
    });
    let price = +iconPrice.replace(/\D/g, "");
    const iconHtml = `<li class="basket__item item" data-icon-id=${iconId}>
                    <picture class="basket__item-picture">
                      <source srcset=${iconPhotoWebp} type="image/webp"><img class="basket__item-image" src=${iconPhoto} alt="Икона ${iconName}">
                    </picture>
                    <div class="basket__item-main"><a class="basket__item-link" href=${iconLink}>${iconName}</a>
                      <p class="basket__item-price">${price.toLocaleString()} ₽</p>
                    </div>
                    <button class="basket__item-trash" data-icon-id=${iconId}><img src="./img/svg/trash.svg" alt="Удалить икону ${iconName}?"></button>
                  </li>`;

    basketList.append(iconHtml);
    let totalPrice = 0;
    $(".basket__item-price", basketList[0]).each(function () {
      totalPrice += +this.textContent.replace(/\D/g, "");
    });
    basketTotalPrice.text(`${totalPrice.toLocaleString()} ₽`);
    basketDropdownPriceDesktop.text(`${totalPrice.toLocaleString()} ₽`);
    basketDropdownPriceDesktop.removeClass("header__basket-price_empty");
    basketDropdownPriceMobile.removeClass("header-mobile__basket-price_empty");
  }
  setTimeout(() => {
    $(`.basket__item[data-icon-id=${iconId}]`).click(removeBasketIcon);
    if ($("html,body").width() > 1023) {
      basketDropdownDesktop.dropdown("show");
      document.addEventListener("click", hideBasketDropdownDesktop);
    } else {
      basketDropdownMobile.dropdown("show");
    }
  }, 1);

}

const hideBasketDropdownDesktop = (e) => {
  if (e.target.parentElement?.classList.contains("basket__item-trash") || e.target.classList.contains("basket__item-trash")) return;
  basketDropdownDesktop.dropdown("hide");
  document.removeEventListener("click", hideBasketDropdownDesktop);
};

function removeBasketIcon(e) {
  console.log(`Удалить Икону с id ${e.currentTarget.dataset.iconId} из корзины`);
  $(`.basket__item[data-icon-id=${e.currentTarget.dataset.iconId}]`).each(function () {
    this.remove();
  });
  let totalPrice = 0;
  $(".basket__item-price", basketList[0]).each(function () {
    totalPrice += +this.textContent.replace(/\D/g, "");
  });
  if (totalPrice === 0) {
    basketList.append(`<li class="basket__item item" data-icon-id="empty">
                    <p class="basket__item-link">Корзина пуста</p>
                  </li>`);
    basketDropdownPriceDesktop.text("Корзина пуста");
    basketDropdownPriceDesktop.addClass("header__basket-price_empty");
    basketDropdownPriceMobile.addClass("header-mobile__basket-price_empty");
  } else {
    basketDropdownPriceDesktop.text(`${totalPrice.toLocaleString()} ₽`);
  }

  basketTotalPrice.text(`${totalPrice.toLocaleString()} ₽`);
}

// кнопка добавления иконы в корзину
// $(".icon-cart__btn_buy").click((e) => {
//   e.preventDefault();
//   console.log("Добавить в корзину икону с id ", e.currentTarget.dataset.iconId);
//   addBasketIcon(e.currentTarget.dataset);
// });
// удаление из корзины
// $(".basket__item-trash").click(removeBasketIcon);

document.addEventListener('DOMContentLoaded', function () {
  const rangeSliderMs2Init = () => { // создаем функцию инициализации слайдера
    const range = document.getElementById('mse2_ms|price_slider'); // Ищем слайдер
    const inputMin = document.getElementById('mse2_ms|price_0'); // Ищем input с меньшим значнием
    const inputMax = document.getElementById('mse2_ms|price_1'); // Ищем input с большим значнием
    
    if (!range || !inputMin || !inputMax) return; // если этих элементов нет, прекращаем выполнение функции, чтобы не было ошибок
  
    const inputs = [inputMin, inputMax]; // создаем массив из меньшего и большего значения
  
    noUiSlider.create(range, { // инициализируем слайдер
        start: [inputMin.value, inputMax.value], // устанавливаем начальные значения
        connect: true, // указываем что нужно показывать выбранный диапазон
        range: { // устанавливаем минимальное и максимальное значения
          "min": Number(inputMin.value),
          "max": Number(inputMax.value)
        },
        step: 1, // шаг изменения значений
      }
    );
  
    range.noUiSlider.on("update", function (values, handle) { // при изменений положения элементов управления слайдера изменяем соответствующие значения
      inputs[handle].value = parseInt(values[handle]);
    });
  
    inputMin.addEventListener("change", function () { // при изменении меньшего значения в input - меняем положение соответствующего элемента управления
      range.noUiSlider.set([this.value, null]);
    });
  
    inputMax.addEventListener("change", function () { // при изменении большего значения в input - меняем положение соответствующего элемента управления
      range.noUiSlider.set([null, this.value]);
    });
  };

  rangeSliderMs2Init();
}, false);

// на страницах икон на заказ при изменении полей индивидуального размера - эти поля становятся обязаетльными а поле с ценой прячется, а на его место идет Цену уточняйте...
$('.js-custom-size-height').on('change', function () {
  if ($(this).val()) {
      $('.js-custom-size-height').prop('required', true);
      $('.js-custom-size-width').prop('required', true);
      $('.js-price-main').css('display', 'none');
        $('.js-price-hidden').css('display', 'block');
    } else {
        $('.js-custom-size-height').prop('required', false);
      $('.js-custom-size-width').prop('required', false);
        $('.js-price-hidden').css('display', 'none');
        $('.js-price-main').css('display', 'block');
    }
});

$('.js-custom-size-width').on('change', function () {
  if ($(this).val()) {
      $('.js-custom-size-height').prop('required', true);
      $('.js-custom-size-width').prop('required', true);
      $('.js-price-main').css('display', 'none');
        $('.js-price-hidden').css('display', 'block');
    } else {
        $('.js-custom-size-height').prop('required', false);
      $('.js-custom-size-width').prop('required', false);
        $('.js-price-hidden').css('display', 'none');
        $('.js-price-main').css('display', 'block');
    }
});

//загрузка изображений для страниц икон

$('#image').change(function(){ 
  $('#image-upload_error').html('');
  var error = '';
  jQuery.each($('#image')[0].files, function(i, file) { 
      var fn = file.name;

      if(file.name.length < 1) { 
          error = error + ' Слишком короткое имя! ';
      } //Проверка на длину имени
      if(file.size > 1024 * 3 *1024) {
          error = error +  file.name + '- Слишком большой файл .';
      } //Проверка размера файла
      if(file.type != 'image/png' && file.type != 'image/jpg' && !file.type != 'image/gif' && file.type != 'image/jpeg' ) {
          error = error + 'Файл  ' + file.name + '  имеет недопустимое расширение  (png, jpg, gif) ';
      } //Проверка типа файлов
  });

  if (error != '') { 
      $(`#image-upload_error`).html(error);
      $('#image_preview').html("");
      $('#image_default-preview').removeClass('hidden');
      $('#image').prop('files', FileListItem([]));
  } else { 
  
      var file_data = $('#image').prop('files')[0];
      
      if (file_data) { 
          var date = new Date();
          var id = '' + date.getFullYear() + '' + date.getMonth() + '' + date.getDate() + '' + date.getHours() + '';
          var file_dir = 'uploads/' + id + file_data.name + '';
          var form_data = new FormData();
          form_data.append('file', file_data);
          form_data.append('filr_dir', file_dir);
          $.ajax({ 
              url: 'upload.php',
              dataType: 'text',
              cache: false,
              contentType: false,
              processData: false,
              data: form_data,
              type: 'post',
              success: function(php_script_response){ 
                  $('#js-set-image-dir').val(file_dir);
              }
          });
      }
  }
});

//скрипты для корзины 

let origin_amount = $('.js-get-amount').html();

if (origin_amount) {
  origin_amount = origin_amount.replace(/\s+/g, '');

  const totalPriceChange = ( amount ) => {
    const dynamic_total_cost_selector = $('.js-total-cost');
  
    if (dynamic_total_cost_selector) {
      let dynamic_total_cost = 0;
      let total_cost = $('.ms2_total_cost').html().replace(/\s+/g, '');
      
      if (!amount) {
          amount = 0;
      }
      
      dynamic_total_cost = Number(total_cost) - Number(amount);
      
      dynamic_total_cost_selector.html(dynamic_total_cost);
    }
  }
  
  totalPriceChange( origin_amount );
  
  $(document).on('mspc2_set', function (e, response) {
      totalPriceChange( response.data.discount_amount );
  });
    
  $(document).on('mspc2_unset', function (e, response) {
      totalPriceChange( 0 );
  });
}

//промокоды

const promocodes = window.PROMOCODES || null;
if (promocodes) {
  const totalCostSelector = document.querySelector('.js-get-cost');
  const couponButtonSelector = document.querySelector('.js-coupon-button');
  const couponInputSelector = document.querySelector('.js-coupon-input');
  const couponAmountWrapperSelector = document.querySelector('.js-coupon-amount-wrapper');
  const couponAmountSelector = document.querySelector('.js-coupon-amount');
  const couponTotalWrapperSelector = document.querySelector('.js-coupon-total-wrapper');
  const couponTotalSelector = document.querySelector('.js-coupon-total');
  const couponErrorWrapperSelector = document.querySelector('.js-coupon-error');
  const orderInput = document.querySelector('.js-order-input input');

  if (orderInput) {
    orderInput.value = '';
  }
  
  const getCouponDiscount = (code) => {
    if (promocodes) {
      for (const number in promocodes) {
        const promocode = promocodes[number];
        for (const key in promocode) {
          if (promocode['code'] === md5(code)) {
            return promocode['discount'];
          }
        }
      }
    } else {
      return false;
    }
  }
  
  couponButtonSelector.addEventListener('click', function (event) {
    const couponValue = couponInputSelector.value;
    const couponDiscount = getCouponDiscount(couponValue);
    let orderInputValue = '';
    
    if (couponDiscount) {
        const totalCost = Number(document.querySelector('.js-get-cost').innerHTML.replaceAll(' ', ''));
        let digitDiscount = 0;
    
        if (couponDiscount.endsWith('%')) {
          digitDiscount = totalCost / 100 * Number((couponDiscount).replaceAll('%', ''));
        } else {
          digitDiscount = couponDiscount;
        }
    
        if (couponAmountWrapperSelector.classList.contains('checkout-item-hidden')) {
          couponAmountWrapperSelector.classList.remove('checkout-item-hidden');
        }

        let finalCost = totalCost - digitDiscount;

        console.log(typeof(digitDiscount));

        digitDiscount = digitDiscount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    
        couponAmountSelector.innerHTML = digitDiscount;
        
        if (couponTotalWrapperSelector.classList.contains('checkout-item-hidden')) {
          couponTotalWrapperSelector.classList.remove('checkout-item-hidden');
        }
        
        console.log(typeof(finalCost));

        finalCost = finalCost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        
        couponTotalSelector.innerHTML = finalCost;
        
        if (orderInput) {
          orderInputValue = 'Промокод ' + couponValue + ', итоговая цена ' + finalCost;
          orderInput.value = orderInputValue;
        }
    } else {
      if (orderInput) {
        orderInput.value = '';
      }
      
      if (!couponAmountWrapperSelector.classList.contains('checkout-item-hidden')) {
        couponAmountWrapperSelector.classList.add('checkout-item-hidden');
      }
      
      if (!couponTotalWrapperSelector.classList.contains('checkout-item-hidden')) {
        couponTotalWrapperSelector.classList.add('checkout-item-hidden');
      }
  
      couponErrorWrapperSelector.classList.remove('checkout-item-hidden');
      setTimeout(() => {
        couponErrorWrapperSelector.classList.add('checkout-item-hidden');
      }, 2000);
    }
  })
}
