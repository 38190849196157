// Поисковая строка

//Данный для поиска
const content = [
  {

    image: "/img/icons/3.png",
    title: "Владимирская Икона Божией Матери",
    price: "7 500 ₽",
    url: "/icon.html"
  },
  {
    title: "Владимирская Икона Божией Матери 2",
    image: "/img/icons/3.png",
    price: "7 500 ₽",
    url: "/icon.html"
  },
  {
    title: "Владимирская Икона Божией Матери 3",
    image: "/img/icons/3.png",
    price: "7 500 ₽",
    url: "/icon.html"
  }
];

// Инициализация поиска
$(".ui.search")
  .search({
    source: content,
    searchFields: [
      "title"
    ],
  });


/*
// Поиск по api https://semantic-ui.com/modules/search.html#/examples
$('.ui.search')
  .search({
    apiSettings: {
      url: '//api.github.com/search/repositories?q={query}'
    },
    fields: {
      results : 'items',
      title   : 'name',
      url     : 'html_url'
    },
    minCharacters : 3
  })
;
*/

// Смена сообщения с пустым результатом поиска
$.fn.search.settings.error.noResults = "";

// для корректной высоты на мобильных устройствах
const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--app-height", `${window.innerHeight}px`);

};
window.addEventListener("resize", appHeight);
appHeight();


// Инициализация дропдаунов
const dropdownCatalog = $(".header__catalog-dropdown.ui.dropdown");
const dropdownBasket = $(".header__basket-dropdown.ui.dropdown.desktop");

dropdownCatalog.dropdown();
dropdownBasket.dropdown(
  {on: "hover"}
);

//функция для скрытия/показа хедера при прокрутке
let isUp = false;
(function () {

  var doc = document.documentElement;
  var w = window;

  /*
define four variables: curScroll, prevScroll, curDirection, prevDirection
*/

  var curScroll;
  var prevScroll = w.scrollY || doc.scrollTop;
  var curDirection = 0;
  var prevDirection = 0;

  var lastY = 0;

  /*
how it works:
-------------
create a scroll event listener
create function to check scroll position on each scroll event,
compare curScroll and prevScroll values to find the scroll direction
scroll up - 1, scroll down - 2, initial - 0
then set the direction value to curDirection
compare curDirection and prevDirection
if it is different, call a function to show or hide the header
example:
step 1: user scrolls down: curDirection 2, prevDirection 0 > hide header
step 2: user scrolls down again: curDirection 2, prevDirection 2 > already hidden, do nothing
step 3: user scrolls up: curDirection 1, prevDirection 2 > show header
*/

  var header = document.querySelector(".header");
  var body = document.getElementsByTagName("body")[0];
  var toggled;
  var downThreshold = 150;
  var upThreshold = 100;

  var checkScroll = function () {
    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      // scrolled down
      curDirection = 2;
    } else {
      //scrolled up
      curDirection = 1;
    }

    if (curDirection !== prevDirection) {
      toggled = toggleHeader();
    } else {
      lastY = curScroll;
    }

    prevScroll = curScroll;
    if (toggled) {
      prevDirection = curDirection;
    }
  };

  var toggleHeader = function () {
    toggled = true;
    if (curDirection === 2 && (curScroll - lastY) > downThreshold) {
      lastY = curScroll;
      isUp = false;
      header.classList.add("header_hidden");
      body.classList.add("header-hidden");
      dropdownCatalog.dropdown("hide");
      dropdownBasket.dropdown("hide");

    } else if (curDirection === 1 && (lastY - curScroll) > upThreshold) {
      lastY = curScroll;
      isUp = true;
      header.classList.remove("header_hidden");
      body.classList.remove("header-hidden");
    } else {
      toggled = false;
    }
    return toggled;
  };

  window.addEventListener("scroll", checkScroll);

})();


// Управление слайдером на странице о нас
const carouselArrow = document.querySelector(".history-carousel__progress-bar-arrow");

const aboutCarouselObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {

    if (entry.isIntersecting) {
      entry.target.classList.add("history-carousel__item_view");
      if (!isUp) {
        carouselArrow.classList.add("history-carousel__progress-bar-arrow_" + entry.target.dataset.year);
      } else {
      }
    } else {
      if (isUp) {
        carouselArrow.classList.remove("history-carousel__progress-bar-arrow_" + entry.target.dataset.year);
        entry.target.classList.remove("history-carousel__item_view");
      }
    }

  });
});

document.querySelectorAll(".history-carousel__item").forEach((quote, index) => {
  aboutCarouselObserver.observe(quote);
  if (index) quote.style.setProperty("top", `-${(index) * 182}px`);
});
