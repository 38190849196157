/*const shortVideo = document.querySelector('short-about__main-video');

if (shortVideo) {
  const shortPalyer = videojs("short-about__main-video", {});

  const socialLinkContainer = document.querySelector(".short-about__main-links");

  const hideSocialLink = () => {
    if (socialLinkContainer.classList.contains("short-about__main-links_hide")) return;
    socialLinkContainer.classList.add("short-about__main-links_hide");
    console.log("hide");
  };

  shortPalyer.on("play", hideSocialLink);

}*/

